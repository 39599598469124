import React, { ReactElement, JSXElementConstructor, ReactFragment, Key } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
// import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DilutedRecoveryPotion from '../../../img/diluted-recovery-potion.jpg';
import PotentRecoveryPotion from '../../../img/potent-recovery-potion.jpg';
import RecoveryPotion from '../../../img/recovery-potion-sm.jpg';
import SuperiorRecoveryPotion from '../../../img/superior-recovery-potion.jpg';
import AssassinsElixirImg from '../../../img/assassins-elixir.jpg';
import LuckElixirImg from '../../../img/luck-elixir.jpg';
import MagesElixirImg from '../../../img/mages-elixir.jpg';
import RangersElixirImg from '../../../img/rangers-elixir.jpg';
import VialOfHolyWaterImg from '../../../img/vial-of-holy-water-sm.jpg';
import VialOfMagicInkImg from '../../../img/vial-of-magic-ink-sm.jpg';
import WarriorsElixirImg from '../../../img/warriors-elixir.jpg';
import WashedParchmentImg from '../../../img/washed-parchment-sm.jpg';
import BlessedParchmentImg from '../../../img/blessed-parchment-sm.jpg';
import BlessingScrollImg from '../../../img/blessing-scroll-sm.jpg';
import RiskyScrollImg from '../../../img/risky-scroll-sm.jpg';
import CursedScrollImg from '../../../img/cursed-scroll-sm.jpg';
import ChaosScrollImg from '../../../img/chaos-scroll-sm.jpg';
import ExaltedScrollImg from '../../../img/exalted-scroll-sm.jpg';
import MiracleScrollImg from '../../../img/miracle-scroll-sm.jpg';
import LesserChaosScrollImg from '../../../img/lesser-chaos-scroll-sm.jpg';
import LesserPositiveScrollImg from '../../../img/lesser-positive-scroll-sm.jpg';
import { recipeMaterials, staticUrl, colors, gameItems } from '../../../utils/utils';

const consumablesTemp = [
  { key: "VOHW", name: "Vial of Holy Water", imgSrc: VialOfHolyWaterImg },
  { key: "VOMI", name: "Vial of Magic Ink", imgSrc: VialOfMagicInkImg },
  { key: "WP", name: "Washed Parchment", imgSrc: WashedParchmentImg },
  { key: "BP", name: "Blessed Parchment", imgSrc: BlessedParchmentImg },
  { key: 'divider1', name: 'divider' },
  { key: "DRP", name: "Diluted Recovery Potion", imgSrc: DilutedRecoveryPotion },
  { key: "RP", name: "Recovery Potion", imgSrc: RecoveryPotion },
  { key: "PRP", name: "Potent Recovery Potion", imgSrc: PotentRecoveryPotion },
  { key: "SRP", name: "Superior Recovery Potion", imgSrc: SuperiorRecoveryPotion },
  { key: 'divider2', name: 'divider' },
  { key: "AE", name: "Assassin's Elixir", imgSrc: AssassinsElixirImg },
  { key: "LE", name: "Luck Elixir", imgSrc: LuckElixirImg },
  { key: "ME", name: "Mage's Elixir", imgSrc: MagesElixirImg },
  { key: "RE", name: "Ranger's Elixir", imgSrc: RangersElixirImg },
  { key: "WE", name: "Warrior's Elixir", imgSrc: WarriorsElixirImg }
];

const scrolls = [
  { key: "BS", name: "Blessing Scroll", imgSrc: BlessingScrollImg },
  { key: "ChS", name: "Chaos Scroll", imgSrc: ChaosScrollImg },
  { key: "CS", name: "Cursed Scroll", imgSrc: CursedScrollImg },
  { key: "ES", name: "Exalted Scroll", imgSrc: ExaltedScrollImg },
  { key: "LChS", name: "Lesser Chaos Scroll", imgSrc: LesserChaosScrollImg },
  { key: "LPS", name: "Lesser Positive Scroll", imgSrc: LesserPositiveScrollImg },
  { key: "MS", name: "Miracle Scroll", imgSrc: MiracleScrollImg },
  { key: "RS", name: "Risky Scroll", imgSrc: RiskyScrollImg },
];

function Crafting() {
  const [selectedRecipe, setSelectedRecipe] = React.useState<Key | null>(null);
  const renderItems = (items: any) => items.map((el: { name: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | null | undefined; key: Key | null | undefined; imgSrc: string | undefined; }) => {
    if (el.name === 'divider') {
      return <Divider key={el.key} />
    }

    return (
      <ListItemButton key={el.key} onClick={() => { if (el.key) setSelectedRecipe(el.key) }}>
        <ListItemAvatar>
          <Avatar
            alt={`${el.name} image`}
            src={el.imgSrc}
          />
        </ListItemAvatar>
        <ListItemText primary={el.name} />
      </ListItemButton>
    );
  });

  const consumableRecipes = renderItems(consumablesTemp);
  const scrollRecipes = renderItems(scrolls);
  console.log(selectedRecipe);

  return (
    <Box>
      <Typography variant='h3' sx={{ textAlign: 'center', color: colors.dark }}>CRAFTING GUIDE</Typography>
      <Box sx={{ maxHeight: '600px', height: '600px', mt: 5 }}>
        {/* <Paper elevation={3} sx={{ bgcolor: '#fef5df' }}> */}
        <Stack direction={{ xs: 'column', md: 'row', }}>
          <Box>
            {/* <Typography variant='h5'>Select a recipe</Typography> */}
            <List sx={{ height: '550px', overflowY: 'auto', pt: 0 }}>
              <ListSubheader sx={{ bgcolor: '#fef5df' }}>Consumable Recipes</ListSubheader>
              {consumableRecipes}
              <ListSubheader sx={{ bgcolor: '#fef5df' }}>Scroll Recipes</ListSubheader>
              {scrollRecipes}
            </List>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Stack
              direction="row"
              sx={{
                height: '526px',
                width: '100%',
                mt: 3,
                pl: 5,
                overflowY: 'auto'
              }}
            >
              { selectedRecipe === null ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '75%' }}>
                  <Typography variant="h5" sx={{ color: colors.lightGray }}>Select a recipe to start...</Typography>
                </Box>
              ) : (
                <Box sx={{width: '100%' }}>
                  <Typography sx={{ pb: 2, textAlign: 'center', fontSize: '2rem' }}>
                    {gameItems[selectedRecipe as keyof typeof gameItems].name}
                  </Typography>
                  {recipeMaterials[selectedRecipe as keyof typeof recipeMaterials].materials.map((el, index) =>
                    (
                      <Stack key={index} direction="column" sx={{ mb: index + 1 === recipeMaterials[selectedRecipe as keyof typeof recipeMaterials].materials.length ? 0 : 5 }}>
                        <Stack direction="row" sx={{ position: "relative", borderBottom: `2px solid ${colors.lightGray}` }}>
                          {recipeMaterials[selectedRecipe as keyof typeof recipeMaterials].materials.length > 1 && (
                            <Chip label={index+1} sx={{position: "absolute" }} size="small" />
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flex: 1,
                              borderRight: `2px solid ${colors.lightGray}`,
                              textAlign: "center"
                            }}
                            >
                              <Typography sx={{ color: colors.gray }}>Required Materials</Typography>
                          </Box>
                          <Box sx={{ flex: 2 }}>
                            {el.map((material, i) => (
                              <Box sx={{ px: 1, my: .5 }} key={i}>
                                <p style={{ marginBottom: 0, marginTop: 0 }}>
                                  {material.quantity}x {material.type.name}
                                </p>

                                <Stack direction="row" flexWrap="wrap">
                                  {material.length.map((_, index) => (
                                    <Box sx={{ pr: .5 }} key={index}>
                                      <img
                                        key={index}
                                        alt={material.type.name}
                                        title={material.type.name}
                                        src={`${staticUrl}/${material.type.img}.png`}
                                      />
                                    </Box>
                                  ))}
                                </Stack>
                              </Box>
                            ))}
                          </Box>
                        </Stack>
                        <Stack direction="row">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flex: 1,
                              borderRight: `2px solid ${colors.lightGray}`,
                              textAlign: 'center'
                            }}
                          >
                            <Typography sx={{ color: colors.gray }}>ELM Shards Cost</Typography>
                          </Box>
                          <Box sx={{ flex: 2 }}>
                            <Box sx={{ px: 1, my: .5 }}>
                              <Stack direction="row" flexWrap="wrap">
                                <Typography>{recipeMaterials[selectedRecipe as keyof typeof recipeMaterials].cost[index].quantity}</Typography>
                                <Box sx={{ px: .5 }}>
                                  <img
                                    alt="ELM Shard"
                                    title="ELM Shard"
                                    src={`${staticUrl}/elm-shard.png`}
                                  />
                                  </Box>
                                </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Stack>
                    )
                  )}
                </Box>
              )}
            </Stack>
          </Box>
          { selectedRecipe && (
            <Box sx={{ mx: 5, mt: 5 }}>
              <Card sx={{ maxWidth: 250 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image={`${staticUrl}/${gameItems[selectedRecipe as keyof typeof gameItems].img}-lg.jpg`}
                  alt={gameItems[selectedRecipe as keyof typeof gameItems].name}
                />
                {gameItems[selectedRecipe as keyof typeof gameItems]?.description && (
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {gameItems[selectedRecipe as keyof typeof gameItems]?.description}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    {gameItems[selectedRecipe as keyof typeof gameItems]?.effects?.map((item, i) => (
                      <Typography variant="body2" color="text.secondary" key={i}>
                        {item}
                      </Typography>
                    ))}
                  </CardContent>
                )}
              </Card>
            </Box>
          )}
        </Stack>
        {/* </Paper> */}
      </Box>
    </Box>
  );
}

export default Crafting;