import * as React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// import ConstructionIcon from '@mui/icons-material/Construction';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListSubheader from '@mui/material/ListSubheader';
// import LoopIcon from '@mui/icons-material/Loop';
import MenuIcon from '@mui/icons-material/Menu';
import ScienceIcon from '@mui/icons-material/Science';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Crafting from './layouts/toe/crafting/Crafting';
import TalesOfElleriaLogo from './img/toe-logo.png'


const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

function App(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  
  const pages = [
    // { text: "Conversion Guide", icon: <LoopIcon /> },  
    { text: "Crafting Guide", icon: <ScienceIcon /> },
    // { text: "Dismantling Guide", icon: <ConstructionIcon /> }
  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
        <img alt="Tales of Elleria logo" src={TalesOfElleriaLogo} style={{ maxWidth: '84px' }} />
      </Box>
      <List>
        {pages.map(el => (
          <ListItem key={el.text} disablePadding>
            <ListItemButton sx={{ color: 'whitesmoke', py: .5 }}>
              <ListItemIcon sx={{ color: 'whitesmoke', justifyContent: 'center' }}>
                {el.icon}
              </ListItemIcon>
              <ListItemText primary={el.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#dc2626' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Magic Guides
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#1d222b' },
        }}
        >
          {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          display: { xs: 'none', sm: 'block' },
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#1d222b', },
        }}
      >
        {drawer}
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, backgroundColor: '#fef5df', height: '100vh' }}
      >
        <Toolbar />
        <Router>
          <Routes>
            <Route path='/' element={<Crafting />} />
            <Route path='talesofelleria/crafting' element={<Crafting />} />
          </Routes>
        </Router>
      </Box>
    </Box>
  );
}

export default App;
