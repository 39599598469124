import { GameItemInterface } from './interfaces';

const AmethystChip = {
  name: "Amethyst Chip",
  img: "amethyst-chip"
};

const AssassinsElixir = {
  name: "Assassin's Elixir",
  img: "assassins-elixir",
  description: "This potion seems to improve your lethality.",
  effects: ["+ 25% Damage and 25% Agility Boost for 10 turns"]
};

const BlessedParchment = {
  name: "Blessed Parchment",
  img: "blessed-parchment"
};

const BlessingScroll = {
  name: "Blessing Scroll",
  img: "blessing-scroll",
  description: "A scroll imbuled with a blessing from the goddess.",
  effects: ["1-3 Random Stat:", "30% +1 ", "40% +2 ", "30% +3"]
};

const BonePowder = {
  name: "Bone Powder",
  img: "bone-powder"
};

const ChaosScroll = {
  name: "Chaos Scroll",
  img: "chaos-scroll",
  description: "An ancient scroll imbuled with chaotic mana. Use this scroll if you wish to rewrite your destiny.",
  effects: ["1-3 Random Stat:", "5% -3", "15% -2", "20% -1 to +1", "15% +2", "5% +3"]
};

const ClothStrips = {
  name: "Cloth Strips",
  img: "cloth-strips"
};

const CursedScroll = {
  name: "Cursed Scroll",
  img: "cursed-scroll",
  description: "An ancient scroll imbuled with a curse. Use this scroll only if you wish the gamble on your own fate.",
  effects: ["Random Stat:", "40% +10", "60% -10"]
};

const DilutedRecoveryPotion = {
  name: "Diluted Recovery Potion",
  img: "diluted-recovery-potion",
  description: "This potion is bursting with vitality. Heroes can consume this for a minor recovery effect.",
  effects: ["Recovers 10% HP Instantly"]
};

const ElmShard = {
  name: "ELM Shard",
  img: "elm-shard"
};

const EmeraldChip = {
  name: "Emerald Chip",
  img: "emerald-chip"
};

const ExaltedScroll = {
  name: "Exalted Scroll",
  img: "exalted-scroll",
  description: "A scroll imbuled with a blessing from the goddess. Use this scroll for a chance to strengthen your heroes.",
  effects: ["1 Random Stat:", "40% +2", "30% +3", "20% +4", "10% +5"]
};

const FourLeafedClover = {
  name: "Four Leafed Clover",
  img: "four-leafed-clover"
};

const Leather = {
  name: "Leather",
  img: "leather"
};

const LesserChaosScroll = {
  name: "Lesser Chaos Scroll",
  img: "lesser-chaos-scroll",
  description: "An ancient scroll secreting a hint of chaotic mana. Use this scroll if you wish to rewrite your destiny.",
  effects: ["1 Random Stat:", "33% -1 to +1"]
};

const LesserPositiveScroll = {
  name: "Lesser Positive Scroll",
  img: "lesser-positive-scroll",
  description: "An scroll with a hint of Luck. You have a good feeling about this.",
  effects: ["1 Random Stat:", "100% +1"]
};

const LuckElixir = {
  name: "Luck Elixir",
  img: "luck-elixir",
  description: "You feel as if the world moves in your favour.",
  effects: ["+ 10% Hit Rate for 10 turns, ?????"]
};

const MagesElixir = {
  name: "Mage's Elixir",
  img: "mages-elixir",
  description: "This potion seems to improve your sensitivity to mana.",
  effects: ["+ 25% Damage and 25% Resistance Boost for 10 turns"]
};

const MagicShard = {
  name: "Magic Shard",
  img: "magic-shard"
};

const MiracleScroll = {
  name: "Miracle Scroll",
  img: "miracle-scroll",
  description: "A scroll imbuled with a miraculous blessing.",
  effects: ["3 Random Stat:", "10% +1", "20% +2", "40% +3", "20% +4", "10% +5"]
};

const PotentRecoveryPotion = {
  name: "Potent Recovery Potion",
  img: "potent-recovery-potion",
  description: "This potion is bursting with vitality. Heroes can consume this for a strong recovery effect.",
  effects: ["Recovers 40% HP Instantly"]
};

const RangersElixir = {
  name: "Ranger's Elixir",
  img: "rangers-elixir",
  description: "This potion seems to improve your agility.",
  effects: ["+ 25% Max HP (within the quest) and 25% Agility Boost for 10 turns"]
};

const RecoveryPotion = {
  name: "Recovery Potion",
  img: "recovery-potion",
  description: "This potion is bursting with vitality. Heroes can consume this for a decent recovery effect.",
  effects: ["Recovers 20% HP Instantly"]
};

const RiskyScroll = {
  name: "Risky Scroll",
  img: "risky-scroll",
  description: "An ancient scroll imbuled with evil mana. No one in the right mind would use this scroll.",
  effects: ["1 Random Stat:", "30% -1", "30% -2", "30% -3", "10% +10"]
};

const RubyChip = {
  name: "Ruby Chip",
  img: "ruby-chip"
};

const SuperiorRecoveryPotion = {
  name: "Superior Recovery Potion",
  img: "superior-recovery-potion",
  description: "This potion is bursting with vitality. Heroes can consume this for a legendary recovery effect.",
  effects: ["Recovers 80% HP Instantly"]
};

const SapphireChip = {
  name: "Sapphire Chip",
  img: "sapphire-chip"
};

const ThreeLeafedClover = {
  name: "Three Leafed Clover",
  img: "three-leafed-clover"
};

const VOHW = {
  name: "Vial of Holy Water",
  img: "vial-of-holy-water"
}

const VOMB = {
  name: "Vial of Mysterious Blood",
  img: "vial-of-mysterious-blood"
};

const VOMI = {
  name: "Vial of Magic Ink",
  img: "vial-of-magic-ink"
};

const WarriorsElixir = {
  name: "Warrior's Elixir",
  img: "warriors-elixir",
  description: "This potion seems to improve your defense.",
  effects: ["+ 25% Max HP (within the quest) and 25% Defence Boost for 10 turns"]
};

const WashedParchment = {
  name: "Washed Parchment",
  img: "washed-parchment"
};

const WeirdParchment = {
  name: "Weird Parchment",
  img: "weird-parchment"
};

const WoodChips = {
  name: "Wood Chips",
  img: "wood-chips"
};

export const recipeMaterials = {
  VOHW: {
    materials: [
      [
        { quantity: 5, type: VOMB, length: [1,2,3,4,5] },
        { quantity: 1, type: WoodChips, length: [1] },
        { quantity: 1, type: ClothStrips, length: [1] },
      ]
    ],
    cost: [
      { quantity: 5, length: [1,2,3,4,5] }
    ]
  },
  VOMI: {
    materials: [
      [
        { quantity: 10, type: VOMB, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 5, type: VOHW, length: [1,2,3,4,5] },
        { quantity: 10, type: MagicShard, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 3, type: WoodChips, length: [1,2,3] },
      ]
    ],
    cost: [
      { quantity: 5 }
    ]
  },
  WP: {
    materials: [
      [
        { quantity: 5, type: WeirdParchment, length: [1,2,3,4,5] },
        { quantity: 1, type: VOHW, length: [1] },
      ]
    ],
    cost: [
      { quantity: 3 }
    ]
  },
  BP: {
    materials: [
      [
        { quantity: 10, type: WashedParchment, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 10, type: MagicShard, length: [1,2,3,4,5,6,7,8,9,10] },
      ],
      [
        { quantity: 15, type: WashedParchment, length: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15] },
        { quantity: 1, type: ElmShard, length: [1] },
      ]
    ],
    cost: [
      { quantity: 5 },
      { quantity: 12 }
    ]
  },
  DRP: {
    materials: [
      [
        { quantity: 2, type: VOMB, length: [1,2] },
        { quantity: 1, type: WoodChips, length: [1] },
      ],
    ],
    cost: [
      { quantity: 2 }
    ]
  },
  RP: {
    materials: [
      [
        { quantity: 1, type: DilutedRecoveryPotion, length: [1] },
        { quantity: 2, type: BonePowder, length: [1,2] },
        { quantity: 2, type: WoodChips, length: [1,2] },
      ],
    ],
    cost: [
      { quantity: 5 }
    ]
  },
  PRP: {
    materials: [
      [
        { quantity: 1, type: RecoveryPotion, length: [1] },
        { quantity: 4, type: BonePowder, length: [1,2,3,4] },
        { quantity: 4, type: WoodChips, length: [1,2,3,4] },
        { quantity: 1, type: VOHW, length: [1] },
      ],
    ],
    cost: [
      { quantity: 5 }
    ]
  },
  SRP: {
    materials: [
      [
        { quantity: 1, type: PotentRecoveryPotion, length: [1] },
        { quantity: 8, type: BonePowder, length: [1,2,3,4,5,6,7,8] },
        { quantity: 8, type: WoodChips, length: [1,2,3,4,5,6,7,8] },
        { quantity: 2, type: VOHW, length: [1,2] },
      ],
    ],
    cost: [
      { quantity: 10 }
    ]
  },
  AE: {
    materials: [
      [
        { quantity: 1, type: EmeraldChip, length: [1] },
        { quantity: 1, type: ElmShard, length: [1] },
        { quantity: 1, type: WoodChips, length: [1] },
        { quantity: 1, type: VOHW, length: [1] },
      ],
    ],
    cost: [
      { quantity: 15 }
    ]
  },
  LE: {
    materials: [
      [
        { quantity: 1, type: FourLeafedClover, length: [1] },
        { quantity: 1, type: ElmShard, length: [1] },
        { quantity: 1, type: WoodChips, length: [1] },
        { quantity: 1, type: VOHW, length: [1] },
      ],
      [
        { quantity: 10, type: ThreeLeafedClover, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 1, type: ElmShard, length: [1] },
        { quantity: 1, type: WoodChips, length: [1] },
        { quantity: 1, type: VOHW, length: [1] },
      ],
    ],
    cost: [
      { quantity: 15 },
      { quantity: 20 }
    ]
  },
  ME: {
    materials: [
      [
        { quantity: 1, type: SapphireChip, length: [1] },
        { quantity: 1, type: ElmShard, length: [1] },
        { quantity: 1, type: WoodChips, length: [1] },
        { quantity: 1, type: VOHW, length: [1] },
      ],
    ],
    cost: [
      { quantity: 15, length: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15] }
    ]
  },
  RE: {
    materials: [
      [
        { quantity: 1, type: RubyChip, length: [1] },
        { quantity: 1, type: ElmShard, length: [1] },
        { quantity: 1, type: WoodChips, length: [1] },
        { quantity: 1, type: VOHW, length: [1] },
      ],
    ],
    cost: [
      { quantity: 15 }
    ]
  },
  WE: {
    materials: [
      [
        { quantity: 1, type: AmethystChip, length: [1] },
        { quantity: 1, type: ElmShard, length: [1] },
        { quantity: 1, type: WoodChips, length: [1] },
        { quantity: 1, type: VOHW, length: [1] },
      ],
    ],
    cost: [
      { quantity: 15 }
    ]
  },
  BS: {
    materials: [
      [
        { quantity: 1, type: BlessedParchment, length: [1] },
        { quantity: 1, type: VOMI, length: [1] },
        { quantity: 50, type: BonePowder, length: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50] },
        { quantity: 3, type: ThreeLeafedClover, length: [1,2,3] },
        { quantity: 5, type: ElmShard, length: [1,2,3,4,5] },
      ],
    ],
    cost: [
      { quantity: 135 }
    ]
  },
  ChS: {
    materials: [
      [
        { quantity: 3, type: WashedParchment, length: [1,2,3] },
        { quantity: 3, type: VOHW, length: [1,2,3] },
        { quantity: 1, type: ThreeLeafedClover, length: [1] },
        { quantity: 5, type: ElmShard, length: [1,2,3,4,5] },
      ],
    ],
    cost: [
      { quantity: 55 }
    ]
  },
  CS: {
    materials: [
      [
        { quantity: 3, type: WashedParchment, length: [1,2,3] },
        { quantity: 10, type: VOMB, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 10, type: BonePowder, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 5, type: ElmShard, length: [1,2,3,4,5] },
      ],
    ],
    cost: [
      { quantity: 25 }
    ]
  },
  ES: {
    materials: [
      [
        { quantity: 1, type: BlessedParchment, length: [1] },
        { quantity: 1, type: VOMI, length: [1] },
        { quantity: 50, type: BonePowder, length: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50] },
        { quantity: 3, type: ThreeLeafedClover, length: [1,2,3] },
        { quantity: 5, type: ElmShard, length: [1,2,3,4,5] },
      ],
    ],
    cost: [
      { quantity: 135 }
    ]
  },
  MS: {
    materials: [
      [
        { quantity: 2, type: BlessedParchment, length: [1,2] },
        { quantity: 2, type: VOMI, length: [1,2] },
        { quantity: 50, type: BonePowder, length: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50] },
        { quantity: 50, type: Leather, length: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50] },
        { quantity: 3, type: FourLeafedClover, length: [1,2,3] },
        { quantity: 10, type: ThreeLeafedClover, length: [1,2,3,4,5,6,7,8,9,10] },
      ],
    ],
    cost: [
      { quantity: 200 }
    ]
  },
  LChS: {
    materials: [
      [
        { quantity: 20, type: BonePowder, length: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20] },
        { quantity: 10, type: Leather, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 5, type: ClothStrips, length: [1,2,3,4,5] },
        { quantity: 5, type: WoodChips, length: [1,2,3,4,5] },
      ],
    ],
    cost: [
      { quantity: 10 }
    ]
  },
  LPS: {
    materials: [
      [
        { quantity: 10, type: Leather, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 5, type: ElmShard, length: [1,2,3,4,5] },
        { quantity: 2, type: FourLeafedClover, length: [1,2] },
        { quantity: 8, type: ThreeLeafedClover, length: [1,2,3,4,5,6,7,8] },
      ],
    ],
    cost: [
      { quantity: 85 }
    ]
  },
  RS: {
    materials: [
      [
        { quantity: 10, type: WeirdParchment, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 10, type: VOMB, length: [1,2,3,4,5,6,7,8,9,10] },
        { quantity: 10, type: BonePowder, length: [1,2,3,4,5,6,7,8,9,10] },
      ],
    ],
    cost: [
      { quantity: 10 }
    ]
  }
};

export const consumables = [
  { key: "VOHW", name: "Vial of Holy Water" },
  { key: "VOMI", name: "Vial of Magic Ink" },
  { key: "WP", name: "Washed Parchment" },
  { key: "BP", name: "Blessed Parchment" },
  { key: 'divider1', name: 'divider' },
  { key: "DRC", name: "Diluted Recovery Potion" },
  { key: "RC", name: "Recovery Potion" },
  { key: "PRC", name: "Potent Recovery Potion" },
  { key: "SRC", name: "Superior Recovery Potion" },
  { key: 'divider2', name: 'divider' },
  { key: "AE", name: "Assassin's Elixir" },
  { key: "LE", name: "Luck Elixir" },
  { key: "ME", name: "Mage's Elixir"  },
  { key: "RE", name: "Ranger's Elixir" },
  { key: "WE", name: "Warrior's Elixir" }
];

export const gameItems: GameItemInterface = {
  VOHW,
  VOMI,
  WP: WashedParchment,
  BP: BlessedParchment,
  DRP: DilutedRecoveryPotion,
  RP: RecoveryPotion,
  PRP: PotentRecoveryPotion,
  SRP: SuperiorRecoveryPotion,
  AE: AssassinsElixir,
  LE: LuckElixir,
  ME: MagesElixir,
  RE: RangersElixir,
  WE: WarriorsElixir,
  BS: BlessingScroll,
  ChS: ChaosScroll,
  CS: CursedScroll,
  ES: ExaltedScroll,
  MS: MiracleScroll,
  LChS: LesserChaosScroll,
  LPS: LesserPositiveScroll,
  RS: RiskyScroll
};

export const staticUrl = 'https://magic-guides.s3.amazonaws.com/toe';

export const colors = {
  lightGray: '#a8a7a7',
  gray: '#615e5e',
  dark: '#1d222b'
};